import React from 'react';

import { productList } from '../constants/product.list';
import Layout from '../components/layout';

const Products = ({ location }) => (
  <Layout title="Productos" pathname={location.pathname}>
    <div className="w-full flex flex-col p-8">
      <div className="w-full flex justify-center text-blue text-3xl font-semibold py-4 md:pt-8 md:pb-10">
        Nuestros Productos
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        {productList.map((product, i) => (
          <div
            key={i.toString()}
            className="shadow-card w-96 border border-gray md:w-full rounded-xl bg-whitesmoke my-6 md:mx-4 md:flex md:h-80"
          >
            <img
              className="w-full border-gray h-40 object-cover rounded-t-xl mx-auto mb-4 md:m-0 md:rounded-none border-b md:border-b-0 md:border-r-2 md:h-full md:w-64 md:rounded-l-xl"
              src={product.url}
              alt={product.name}
            />
            <div className="text-blue px-6 pb-8 md:py-6 md:px-6">
              <div className="w-full text-xl font-semibold py-2 border-b-2 border-blue">
                {product.name}
              </div>
              {product.description.map(item => (
                <p className="text-sm mt-4">{item}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </Layout>
);

export default Products;
