import Coal from '../images/coal.jpg';
import Hamoc from '../images/hamoc.jpg';
import Mango from '../images/mango.jpg';
import Papaw from '../images/papaw.jpg';
import ConventionalPineapple from '../images/pineapple.jpg';
import OrganicPineapple from '../images/pineapple-organic.jpg';
import DehidratedPineapple from '../images/pineapple-dehidrated.png';

// TODO: Agregar i18n en productos.

export const productList = [
  {
    name: 'Carbón de madera',
    description: [
      'El carbón vegetal es un material combustible sólido, frágil y poroso con un alto contenido en carbono (del orden del 98 %). Se produce por calentamiento de madera y residuos vegetales, llegando a temperaturas de hasta 700°C, en ausencia de oxígeno para evitar su combustión.',
      'El empaque consiste en sacos de papel de 4 y 8 kilos, Este tipo de material permite obtener la resistencia necesaria para el empaque de este producto. Cuenta con dos capas de papel que impide parcialmente el paso de la humedad y demás agentes externos que puedan afectar la calidad de este. El cierre se elabora con costura de hilo y la impresión es a uno o dos colores.',
    ],
    url: Coal,
  },
  {
    name: 'Hamacas',
    description: [
      'Las hamacas fabricadas a mano por artesanos colombianos, de una excelente calidad, es el resultado de la suma de factores geográficos y humanos protegidos por la figura jurídica conocida como "Denominación de Origen". Esta a su vez garantiza al consumidor final o comprador, que está adquiriendo un producto hecho a mano totalmente y no fabricado parcial o totalmente con máquinas, adicionalmente satisface la necesidad de apoyar a las clases sociales menos favorecidas económicamente hablando, pero muy ricas en cultura.',
    ],
    url: Hamoc,
  },
  {
    name: 'Mango Tommy-Atkins',
    description: [
      'El mango de variedad Tommy Atkins es una de porte alto. Es una fruta de color rojo intenso, pesa hasta 700 gramos, la semilla es pequeña y representa el 7% del peso total del fruto. Tiene cáscara relativamente gruesa, es muy firme, posee pocas fibras y son muy pequeñas y delgadas. Es de buena calidad y regular de sabor; se considera de alta producción. Es resistente al manejo de la fruta en plantación y postcosecha, algo tolerante a la antracnosis y al ataque de trips.',
    ],
    url: Mango,
  },
  {
    name: 'Papaya Thai-Nung',
    description: [
      'La papaya contiene papaina, una encima digestiva natural, lycopene y vitamina A, B y C, adicionalmente proporciona catequinas y carotenoides. Investigaciones recientes han demostrado que la papaya previene enfermedades del corazón, degeneración macular y la artritis reumatoide. Fortifica el sistema inmune y tiene efectos anti-inflamatorios.',
    ],
    url: Papaw,
  },

  {
    name: 'Piña MD2 Convencional',
    description: [
      'La piña normal se procesa a partir de la fruta fresca, la variedad seleccionada para este producto es la MD2 o piña Oro Miel, es una piña suave y muy dulce con unas características nutricionales excepcionales. Es un producto natural en el cuál se utilizan los fertilizantes y pesticidas normales para este tipo de cultivos con el fin de generar una fruta más grande, jugosa y de mejor apariencia.',
    ],
    url: ConventionalPineapple,
  },
  {
    name: 'Piña MD2 Orgánica',
    description: [
      'La piña fresca procede de cultivos orgánicos que implican la limitación o el no uso de productos químicos para el control de plagas en los cultivos ni fertilizantes de origen industrial que atenten contra la salud. Esta piña se cultiva en la zona de Los Montes de Maria, de allí el origen de la marca del producto.',
    ],
    url: OrganicPineapple,
  },
  {
    name: 'Piña MD2 Orgánica Deshidratada ',
    description: [
      'La piña deshidratada se procesa a partir de la fruta fresca, la variedad seleccionada para este producto es la MD2 o piña Oro Miel, es una piña suave y muy dulce con unas características nutricionales excepcionales. Es un producto natural y sin la adición de preservantes o químicos que atenten contra la salud.',
    ],
    url: DehidratedPineapple,
  },
];
